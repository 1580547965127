import React, { useMemo } from 'react';

import { ApolloProvider } from '@apollo/client';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/gatsby';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import { ErrorFallback } from 'src/components/error-fallback/error-fallback';
import LanguageProvider from 'src/config/language-provider';
import { ClientConfigProvider } from 'src/config/provider';
import { muiThemeOverride } from 'src/styles/theme';
import { AuthProvider } from 'src/utils/auth/AuthContext';
import { QueryParamProvider } from 'use-query-params';

import initDefaultClient from './client';

export const wrapRootBrowser = ({ element }) => {
  return (
    <AuthProvider>
      <ClientConfigProvider>
        <LanguageProvider>
          <QueryParamProvider>
            <WrapRoot element={element} />
          </QueryParamProvider>
        </LanguageProvider>
      </ClientConfigProvider>
    </AuthProvider>
  );
};

const isBrowser = typeof window !== 'undefined';

class ErrorBoundary extends ReactErrorBoundary {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error) {
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorFallback />;
    }

    return this.props.children;
  }
}

const ErrorBoundaryContainer: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  // TODO: add appInsights context here once set up for CC
  return <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>;
};

export const wrapRootSSR = ({ element }) => {
  return <WrapRoot element={element} />;
};

const WrapRoot: React.FC<React.PropsWithChildren<{ element: any }>> = ({ element }) => {
  // Instantiating client in `wrapRootElement` handler ensures:
  //  - there is fresh client for each SSR page
  //  - it will be called only once in browser, when React mounts
  const client = useMemo(initDefaultClient, []);

  return (
    <ErrorBoundaryContainer>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiThemeOverride}>
          <ToastContainer hideProgressBar pauseOnHover theme="colored" position="top-center" />
          <ApolloProvider client={client}>
            <DndProvider backend={HTML5Backend}>{element}</DndProvider>
          </ApolloProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ErrorBoundaryContainer>
  );
};
